import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import WithLoading from "./WithLoading";
import AuthService from "./AuthService";
import { AppContext } from "./AppProvider";
import ActionButton from "./components/ActionButton.jsx";

const Auth = AuthService.getInstance();
const RaffleWinner = (props) => {
  const { state, dispatch } = useContext(AppContext);
  const [doc, setDoc] = useState({});
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [statusMessage, setStatusMessage] = useState(null);
  const dateOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const dateOptionsShort = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  useEffect(() => {
    setLoading(true);
    Auth.fetch(`/verlosung/teilnehmer/${props.match.params.id}`)
      .then((data) => {
        dispatch({ type: "FETCH_RAFFLEWINNER", data: data });
        setDoc(data);
        setLoading(false);
        setUpdate(false);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [update]);

  useEffect(() => {
    setUpdate(true);
  }, []);

  const approveWinner = async (e, id) => {
    e.preventDefault();
    await Auth.fetch(`/verlosung/approve/${id}`, { method: "POST" });
    setStatusMessage(`Gewinner freigegeben.`);
    return setUpdate(true);
  };

  const rejectWinner = async (e, id) => {
    e.preventDefault();
    await Auth.fetch(`/verlosung/reject/${id}`, { method: "POST" });
    setStatusMessage(`Gewinner abgelehnt.`);
    return setUpdate(true);
  };

  const forceApproveWinner = async (e, id) => {
    e.preventDefault();
    await Auth.fetch(`/verlosung/force-approve/${id}`, { method: "POST" });
    setStatusMessage(`Gewinner manuell freigegeben.`);
    return setUpdate(true);
  };

  const handleDownloadClick = async (file, fileName, fileType) => {
    try {
      // Decode base64-encoded data
      const decodedData = atob(file.data);

      // Convert the decoded data to Uint8Array
      const uint8Array = new Uint8Array(decodedData.length);
      for (let i = 0; i < decodedData.length; i++) {
        uint8Array[i] = decodedData.charCodeAt(i);
      }
      // Convert the attachment data to a Blob
      const blob = new Blob([uint8Array], { type: "application/octet-stream" });

      // Create a download link
      const blobUrl = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = fileName;

      // Trigger the download
      document.body.appendChild(a);
      a.click();

      // Cleanup
      document.body.removeChild(a);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading CouchDB attachment:", error);
    }
  };

  const deleteAttachment = async (e, id) => {
    e.preventDefault();
    await Auth.fetch(`/verlosung/delete-attachment/${id}`, { method: "POST" });
    setStatusMessage(`Dokument entfernt.`);
    return setUpdate(true);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3">
          <button
            onClick={() => props.history.goBack()}
            role="button"
            className="btn btn-outline-primary"
            activeClassName=""
          >
            Zurück
          </button>
          <div className="mt-5">
            <h5>Leistungsnachweise:</h5>
            <div className="mt-3 mb-5 d-flex justify-content-center align-items-center">
              <ActionButton
                type="reject"
                onClick={(e) => rejectWinner(e, doc._id)}
                title="Leistungsbescheid(e) ablehnen"
                className="mr-3 px-4"
                disabled={doc.approved || doc.rejected ? true : false}
              />
              <ActionButton
                type="approve"
                onClick={(e) => approveWinner(e, doc._id)}
                title="Leistungsbescheid(e) freigeben"
                className="mr-3 px-4"
                disabled={doc.approved || doc.rejected ? true : false}
              />
              <ActionButton
                type="delete"
                onClick={(e) => deleteAttachment(e, doc._id)}
                title="Dokument(e) entfernen"
                className={`px-4 ${doc.rejected ? "d-block" : "d-none"}`}
              />
            </div>
            {doc._attachments &&
              Object.keys(doc._attachments).map((attachment, index) => (
                <div className="mb-5" key={index}>
                  <div
                    className={`position-relative ${
                      doc._attachments[attachment].content_type ===
                        "application/pdf" && "aspect-ratio-a4"
                    }`}
                  >
                    {doc._attachments[attachment].content_type ===
                    "application/pdf" ? (
                      <iframe
                        src={`data:application/pdf;base64,${doc._attachments[attachment].data}`}
                        width="100%"
                        height="280%"
                        className="aspect-ratio-a4-inner"
                        type={doc._attachments[attachment].content_type}
                      ></iframe>
                    ) : (
                      <img
                        src={`data:${doc._attachments[attachment].content_type};base64, ${doc._attachments[attachment].data}`}
                        className="w-100"
                      />
                    )}
                  </div>
                  <div className="my-3 d-flex justify-content-end align-items-center">
                    <ActionButton
                      type="download"
                      onClick={() =>
                        handleDownloadClick(
                          doc._attachments[attachment],
                          Object.keys(doc._attachments)[index]
                        )
                      }
                      title="Dokument herunterladen"
                      className="mx-3 px-4"
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="col-md-9">
          <WithLoading loading={loading} waitfor={doc}>
            <table className="table table-striped table-winner">
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>
                    {doc.firstname} {doc.lastname}
                  </td>
                </tr>
                <tr>
                  <td>ID</td>
                  <td>{doc._id}</td>
                </tr>
                <tr>
                  <td>E-Mail-Token</td>
                  <td>{doc.emailToken}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{doc.status}</td>
                </tr>
                <tr>
                  <td>Ausgelost am</td>
                  <td>
                    {doc.winnerDrawnAt &&
                      new Intl.DateTimeFormat("de-DE", dateOptions).format(
                        Date.parse(doc.winnerDrawnAt)
                      )}
                  </td>
                </tr>
                <tr>
                  <td>E-Mail</td>
                  <td>
                    <a href={`mailto:${doc.email}`}>{doc.email}</a>
                  </td>
                </tr>
                <tr>
                  <td>E-Mail Status</td>
                  <td>{doc.emailStatus}</td>
                </tr>
                {/*                 <tr>
                  <td>Ausweisnummer</td>
                  <td>{doc.bgNumber}</td>
                </tr> */}
                <tr>
                  <td>Erinnerungsmail versendet?</td>
                  <td>{doc.reminderSent ? "Ja" : "Nein"}</td>
                </tr>
                <tr>
                  <td>Geburtsdatum</td>
                  <td>
                    {doc.birthdate &&
                      new Intl.DateTimeFormat("de-DE", dateOptionsShort).format(
                        Date.parse(doc.birthdate)
                      )}
                  </td>
                </tr>
                <tr
                  onClick={(e) => {
                    e.stopPropagation();
                    !doc.exported &&
                      !edit &&
                      setEdit(
                        doc.IBANprovided ? doc.IBAN : "Noch nicht eingereicht"
                      );
                  }}
                >
                  <td>IBAN</td>
                  <td>
                    <div
                      className={`form-inline ${
                        !edit && !doc.exported && "text-primary"
                      }`}
                    >
                      {!edit ? (
                        doc.IBANprovided ? (
                          doc.IBAN
                        ) : (
                          "Noch nicht eingereicht"
                        )
                      ) : (
                        <div className={`form-inline`}>
                          <input
                            autoFocus={true}
                            className="form-control flex-grow-1"
                            type="text"
                            placeholder="Iban"
                            value={edit}
                            onChange={(e) => setEdit(e.target.value)}
                          />
                          <button
                            className={`btn btn-primary ml-3`}
                            onClick={(e) => {
                              e.preventDefault();
                              setLoading(true);
                              Auth.fetch(`/verlosung/${doc._id}/iban`, {
                                method: "post",
                                body: JSON.stringify(edit),
                              })
                                .then((data) => {
                                  setEdit(false);
                                  setLoading(false);
                                  setDoc(data.doc);
                                  dispatch({
                                    type: "TOGGLE_TOAST",
                                    data: {
                                      open: true,
                                      type: `success`,
                                      message: `Updated`,
                                    },
                                  });
                                })
                                .catch((e) => {
                                  setLoading(false);
                                  dispatch({
                                    type: "TOGGLE_TOAST",
                                    data: {
                                      open: true,
                                      type: `danger`,
                                      message: `Error: ${JSON.stringify(e)}`,
                                    },
                                  });
                                });
                            }}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Leistungsbescheid</td>
                  <td className="d-flex">
                    {doc.jobcenterDocumentProvided &&
                    !doc.approved &&
                    !doc.rejected
                      ? "Bereit zur Überprüfung"
                      : doc.approved
                      ? "Überprüft und freigegeben"
                      : doc.rejected
                      ? "Überprüft und abgelehnt"
                      : "Warte auf Upload"}{" "}
                    {doc.IBANprovided &&
                      !doc.jobcenterDocumentProvided &&
                      !doc.approved && (
                        <ActionButton
                          type="approve"
                          onClick={(e) => forceApproveWinner(e, doc._id)}
                          title="Ohne Upload freigeben"
                          className="mx-3 px-4"
                          disabled={doc.approved || doc.rejected ? true : false}
                        />
                      )}
                  </td>
                </tr>
                <tr>
                  <td>Daten exportiert?</td>
                  <td>{doc.exported ? "Ja" : "Nein"}</td>
                </tr>
                <tr>
                  <td>Feedbackmail erhalten?</td>
                  <td>{doc.feedbackMailSent ? "Ja" : "Nein"}</td>
                </tr>
              </tbody>
            </table>
          </WithLoading>
        </div>
      </div>
    </div>
  );
};
export default RaffleWinner;
