import React, { useEffect, useState, useContext } from "react";
import "./App.css";
import BlogPost from "./BlogPost.jsx";
import { AppContext } from "./AppProvider.jsx";
import AuthService from "./AuthService.jsx";
import withAuth from "./withAuth.js";
import WithLoading from "./WithLoading.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import PreviewPage from "./PreviewPage.jsx";

const Auth = AuthService.getInstance();
const BlogPreview = function (props) {
  const { state, dispatch } = useContext(AppContext);
  const [doc, setDoc] = useState({}); // '' is the initial state value
  const [loading, setloading] = useState(true);
  useEffect(() => {
    if (state.articles.length === 0) {
      Auth.fetch(`/blog/preview/${props.match.params.id}`).then((data) => {
        dispatch({
          type: "FETCH_ARTICLE",
          data: data,
        });
        // dispatch({
        //   type: 'TOGGLE_TOAST',
        //   data: { open: true, type: `success`, message: `Articles fetched` },
        // })
        setloading(false);
      });
    } else {
      setloading(false);
    }
  }, []);

  const onSaveDraft = (event) => {
    event.preventDefault();
    props.onPostSubmit(event, false);
  };

  const onPublish = (event) => {
    event.preventDefault();
    props.onPostSubmit(event, true);
  };

  return (
    <WithLoading loading={loading} waitfor={state.article}>
      <PreviewPage article={state.article} />
    </WithLoading>
  );
};
export default withAuth(BlogPreview);
