let index;
var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_ARTICLE":
      return { ...state, article: action.data };
    case "UPDATE_ARTICLE":
      const updatedArticles = state.articles.map((article) =>
        article._id === action.data._id ? action.data : article
      );
      return { ...state, articles: updatedArticles };
    case "FETCH_ARTICLES":
      return {
        ...state,
        articles: action.data.articles,
        authors: action.data.authors,
      };
    case "FETCH_AUTHOR":
      return { ...state, author: action.data };
    case "UPDATE_AUTHOR":
      const updatedAuthors = state.authors.map((author) =>
        author._id === action.data._id ? action.data : author
      );
      return { ...state, authors: updatedAuthors };
    case "REMOVE_AUTHOR":
      const authors = [...state.authors];
      index = authors.findIndex((i) => i._id === action.data.id);
      authors.splice(index, 1);
      return { ...state, authors: authors };
    case "FETCH_AUTHORS":
      return { ...state, authors: action.data };
    case "FETCH_SETTING":
      return { ...state, articles: action.data };
    case "FETCH_HARTZBREAKER":
      return { ...state, hartzbreaker: action.data };
    case "FETCH_HARTZBREAKERS":
      return { ...state, hartzbreaker: action.data };
    case "FETCH_RAFFLEPARTICIPANTS":
      return { ...state, confirmed: action.data };
    case "FETCH_RAFFLEWINNERS":
      return { ...state, winners: action.data };
    case "FETCH_BLOCKEDPARTICIPANTS":
      return { ...state, blocked: action.data };
    case "FETCH_RAFFLEWINNER":
      return { ...state, winner: action.data };
    case "FETCH_RAFFLEDUPLICATES":
      return { ...state, duplicates: action.data };
    case "FETCH_BANKDONATIONS":
      const grouped = groupBy(action.data.rows, "key");
      return {
        ...state,
        bankdonations: grouped,
        fullhartzbreaker: action.data.hartzbreaker,
      };
    case "REMOVE_ARTICLE":
      const articles = [...state.articles];
      index = articles.findIndex((i) => i._id === action.data.id);
      articles.splice(index, 1);
      return { ...state, articles: articles };

    // case "FILTER_HARTZBREAKER":
    //   let hartzbreaker = [...state.hartzbreaker];
    //   hartzbreaker = hartzbreaker.filter(doc => {
    //     console.log("doc", doc);

    //     doc.key
    //       .replace(/\s/g, "")
    //       .indexOf(action.data.replace(/\s/g, "")) !== -1 ||
    //       doc.value
    //         .replace(/\s/g, "")
    //         .indexOf(action.data.replace(/\s/g, "")) !== -1;
    //   });
    //   return { ...state, hartzbreaker: hartzbreaker };

    case "FETCH_TODOS":
      return { ...state, todos: action.data };
    case "LOGIN":
      return { ...state, user: action.data };
    case "FETCH_SETTINGS":
      return { ...state, settings: action.data };
    case "TOGGLE_TOAST":
      const time = Date.now();
      return {
        ...state,
        toasts: [...state.toasts, { ...action.data, time: time }],
      };
    case "REMOVE_TOAST":
      const toasts = [...state.toasts];
      index = toasts.find((i) => i.time === action.data);
      toasts.splice(index, 1);
      return { ...state, toasts: toasts };
    case "CLEAR_TOASTS":
      return { ...state, toasts: [] };
    case "REMOVE_TODO":
      const todos = [...state.todos];
      index = todos.findIndex((i) => i.id === action.data);
      todos.splice(index, 1);
      return { ...state, todos: todos };
    case "SETPAGE":
      return { ...state, offset: action.data };
    default:
      throw new Error("Unexpected action");
  }
};

export const initialState = {
  user: [],
  article: [],
  articles: [],
  author: [],
  authors: [],
  hartzbreaker: [],
  fullhartzbreaker: [],
  confirmed: [],
  winners: [],
  all: [],
  duplicates: [],
  blocked: [],
  winner: [],
  bankdonations: [],
  settings: [],
  todos: [],
  toasts: [],
  offset: 0,
  limit: 100,
};
