import React, { useEffect, useState, useContext } from "react";
import "./App.css";
import BlogPost from "./BlogPost.jsx";
import { AppContext } from "./AppProvider.jsx";
import AuthService from "./AuthService.jsx";
import withAuth from "./withAuth";
import WithLoading from "./WithLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import PreviewPage from "./PreviewPage.jsx";

const Auth = AuthService.getInstance();
const Blog = function (props) {
  const { state, dispatch } = useContext(AppContext);
  const [doc, setDoc] = useState({}); // '' is the initial state value
  const [loading, setloading] = useState(true);
  const [preview, setPreview] = useState(false);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    if (state.articles.length === 0) {
      setloading(true);
      Auth.fetch(`/blog`).then((data) => {
        dispatch({
          type: "FETCH_ARTICLES",
          data: {
            articles: data.articles.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            ),
            authors: data.authors,
          },
        });
        // dispatch({
        //   type: 'TOGGLE_TOAST',
        //   data: { open: true, type: `success`, message: `Articles fetched` },
        // })
        setloading(false);
      });
    } else {
      setloading(false);
    }
  }, []);

  useEffect(() => {
    setIsModified(false);
  }, [doc._id, doc._rev]);

  const handleImageChange = (e) => {
    convertAndSetImage(e.target.files[0]);
    setIsModified(true);
  };
  const onPostEdit = (newDoc) => {
    setDoc(newDoc);
  };
  const convertAndSetImage = (data) => {
    data && data.error
      ? console.error(data.message)
      : data.arrayBuffer().then((buffer) => {
          const bufferFile = Buffer.from(buffer).toString("base64");
          setDoc({ ...doc, _attachments: { image: { data: bufferFile } } });
        });
  };

  useEffect(() => {
    if (doc._id) {
      return;
    } else {
      let slug = "";
      doc && doc.title
        ? (slug = doc.title
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, "-")
            .replace(/^-+|-+$/g, ""))
        : (slug = "");

      setDoc({ ...doc, slug: slug });
    }
  }, [doc.title]);

  /*   useEffect(() => {
    if (doc.slug) {
      let newSlug = doc.slug;
      const counterRef = { current: 1 };

      const checkSlug = async () => {
        const response = await Auth.fetch(`/blog?slug=${newSlug}`);
        if (response.length > 0) {
          counterRef.current++;
          newSlug = `${doc.slug}-${counterRef.current}`;
          checkSlug();
        } else {
          setDoc((prevDoc) => ({ ...prevDoc, slug: newSlug }));
        }
      };

      checkSlug();
    }
  }, [doc.slug]); */

  const handlePreview = (event) => {
    event.preventDefault();
    setPreview(!preview);
  };

  const onPostDelete = (post) => {
    Auth.fetch(`/blog/${post._id}`, {
      method: "DELETE",
      body: JSON.stringify({ rev: post._rev }),
    }).then((data) => {
      dispatch({ type: "REMOVE_ARTICLE", data: data });
      dispatch({
        type: "TOGGLE_TOAST",
        data: {
          open: true,
          type: `success`,
          message: `Blog-Artikel ${post._id} gelöscht!`,
        },
      });
    });
  };

  const toggleFeatured = (post) => {
    let { ...postDoc } = post;
    postDoc.featured = !postDoc.featured;
    Auth.fetch(
      `/blog/${post._id}?featured=true`,
      {
        method: "PUT",
        body: JSON.stringify(post),
      },
      true
    ).then((res) => {
      if (res.ok) {
        dispatch({ type: "UPDATE_ARTICLE", data: postDoc });
      } else {
        dispatch({
          type: "TOGGLE_TOAST",
          data: {
            open: true,
            type: `danger`,
            message: `Error: ${JSON.stringify(res.message)}`,
          },
        });
      }
    });
  };
  const onPostSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    const imagedata = document.querySelector('input[type="file"]').files[0];
    let { ...postDoc } = doc;
    if (imagedata) {
      data.append("image", imagedata, imagedata.name);
    }
    let [...articles] = state.articles;
    postDoc.draft = true;
    postDoc.type = "draft";

    if (doc._id) {
      postDoc._id = doc._id;
      postDoc.slug = doc._id;
    } else {
      postDoc._id = postDoc.slug;
    }
    if (doc._rev) {
      postDoc._rev = doc._rev;
    }
    if (doc.createdAt) {
      postDoc.createdAt = doc.createdAt;
    } else {
      postDoc.createdAt = new Date().toISOString();
    }

    let articleIndex = articles.findIndex((obj) => obj._id === doc._id);

    if (articleIndex !== -1) {
      articles.splice(articleIndex, 1);
    }
    setDoc(postDoc);
    articles.unshift(postDoc);
    data.append("doc", JSON.stringify(postDoc));
    Auth.fetch(
      `/blog`,
      {
        method: "POST",
        body: data,
      },
      true
    )
      .then((res) => {
        if (res.error) {
          dispatch({
            type: "TOGGLE_TOAST",
            data: {
              open: true,
              type: `danger`,
              message: `Error: ${JSON.stringify(res.message)}`,
            },
          });
        } else if (res.ok) {
          dispatch({
            type: "FETCH_ARTICLES",
            data: {
              articles: articles,
              authors: state.authors,
            },
          });
          dispatch({
            type: "TOGGLE_TOAST",
            data: {
              open: true,
              type: `success`,
              message: doc._rev ? `Updated` : `Created`,
            },
          });
          setDoc({});
        } else {
          console.log("error", res);
        }
      })
      .catch((e) => {
        console.debug(e);
        dispatch({
          type: "TOGGLE_TOAST",
          data: {
            open: true,
            type: `danger`,
            message: `Error: ${JSON.stringify(e)}`,
          },
        });
      });
  };

  const onPostPublish = (event) => {
    event.preventDefault();
    let { ...postDoc } = doc;
    postDoc.draft = false;
    postDoc.publishedAt = new Date().toISOString();

    Auth.fetch(
      `/blog/${postDoc._id}`,
      {
        method: "PUT",
        body: JSON.stringify(postDoc),
      },
      true
    )
      .then((res) => {
        if (res.error) {
          dispatch({
            type: "TOGGLE_TOAST",
            data: {
              open: true,
              type: `danger`,
              message: `Error: ${JSON.stringify(res.message)}`,
            },
          });
        } else if (res.ok) {
          postDoc.type = "blogpost";
          dispatch({
            type: "UPDATE_ARTICLE",
            data: postDoc,
          });
          dispatch({
            type: "TOGGLE_TOAST",
            data: {
              open: true,
              type: `success`,
              message: `Published`,
            },
          });
          setDoc(postDoc);
          setIsModified(false);

          if (preview) {
            setPreview(false);
          }
        }
      })
      .catch((e) => {
        console.debug(e);
        dispatch({
          type: "TOGGLE_TOAST",
          data: {
            open: true,
            type: `danger`,
            message: `Error: ${JSON.stringify(e)}`,
          },
        });
      });
  };

  const onSaveDraft = (event) => {
    event.preventDefault();
    onPostSubmit(event);
  };

  const onPublish = (event) => {
    event.preventDefault();
    onPostPublish(event);
  };

  const createNewPost = () => {
    setDoc({});
    setIsModified(false);
  };

  const posttemplate = (
    <div className="table-responsive vh-100">
      <table
        id="blogposts"
        className="py-5 table table-striped table-sm text-left "
      >
        <thead>
          <tr>
            <th scope="col">Created</th>
            <th scope="col">Title</th>
            <th colSpan="col" scope="col">
              Image
            </th>
            <th scope="col" className="text-center">
              Status
            </th>
            <th scope="col" className="text-center">
              Featured?
            </th>
          </tr>
        </thead>
        <tbody>
          {state.articles &&
            state.articles.map((doc) => (
              <BlogPost
                key={doc._id}
                onPostEdit={onPostEdit}
                onPostDelete={onPostDelete}
                toggleFeatured={toggleFeatured}
                doc={doc}
              />
            ))}
        </tbody>
      </table>
    </div>
  );

  const handleInputChange = (e, field) => {
    setDoc({ ...doc, [field]: e.target.value });
    setIsModified(true);
  };

  return (
    <WithLoading loading={loading} waitfor={state.articles}>
      <div>
        {preview ? (
          <PreviewPage
            article={doc}
            onPublish={onPublish}
            handlePreview={handlePreview}
            isModified={isModified}
          />
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4">
                {" "}
                <button
                  onClick={createNewPost}
                  className="btn btn-primary btn-lg my-3"
                >
                  Neuer Artikel
                </button>
                {posttemplate}
              </div>
              <div className="col-md-8">
                <form className="form-post mb-5 pt-5">
                  <div className="mb-3">
                    <div className="row">
                      <div className="col-6">
                        <div className="mb-3">
                          <label htmlFor="title" className="sr-only">
                            Header
                          </label>
                          <input
                            value={doc.title || ""}
                            onChange={(e) => handleInputChange(e, "title")}
                            type="text"
                            id="title"
                            className="form-control"
                            placeholder="Header"
                            autoFocus
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="subtitle" className="sr-only">
                            Subheader
                          </label>
                          <input
                            value={doc.subtitle || ""}
                            onChange={(e) => handleInputChange(e, "subtitle")}
                            type="text"
                            id="subtitle"
                            className="form-control"
                            placeholder="Subheader"
                            autoFocus
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-3">
                          <label htmlFor="author" className="sr-only">
                            Author
                          </label>
                          <select
                            value={doc.author || ""}
                            onChange={(e) => handleInputChange(e, "author")}
                            id="author"
                            className="form-control"
                            name="author"
                            required
                          >
                            <option value="" disabled>
                              Select Author
                            </option>
                            {state.authors &&
                              state.authors.length > 0 &&
                              state.authors.map((author) => (
                                <option key={author._id} value={author._id}>
                                  {author.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 d-flex align-items-center justify-content-start pr-0">
                        <span>https://sanktionsfrei.de/blog/</span>
                      </div>
                      <div className="pl-1 pr-0 col">
                        <label htmlFor="slug" className="sr-only">
                          Url
                        </label>
                        <input
                          value={doc._id || doc.slug || ""}
                          onChange={(e) => handleInputChange(e, "slug")}
                          type="text"
                          id="slug"
                          className="form-control pl-1"
                          placeholder="id"
                          autoFocus
                          required
                          disabled={doc._id ? true : false}
                        />
                      </div>
                      <div className="col-1">
                        <button
                          type="button"
                          className="btn btn btn-primary btn-block"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `https://sanktionsfrei.de/blog/${
                                doc._id ? doc._id : doc.slug
                              }`
                            );
                            dispatch({
                              type: "TOGGLE_TOAST",
                              data: {
                                open: true,
                                type: `success`,
                                message: `Link copied to clipboard!`,
                              },
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={faCopy} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="teaser" className="sr-only">
                      Teaser
                    </label>
                    <textarea
                      value={doc.teaser || ""}
                      onChange={(e) => handleInputChange(e, "teaser")}
                      id="teaser"
                      className="form-control"
                      placeholder="Teaser"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="content" className="sr-only">
                      Content
                    </label>
                    <textarea
                      value={doc.content || ""}
                      onChange={(e) => handleInputChange(e, "content")}
                      id="content"
                      className="form-control"
                      placeholder="Content"
                      required
                    />
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-6">
                      <label htmlFor="content" className="sr-only">
                        File
                      </label>
                      <input
                        type="file"
                        name="image"
                        onChange={handleImageChange}
                        className="form-control mb-3"
                        placeholder="Image"
                        required={doc._attachments ? false : true}
                      />
                      <div className="row">
                        <div className="col-6">
                          {doc._id &&
                            (doc.draft === false ? (
                              <button
                                type="button"
                                onClick={onPublish}
                                className={`btn btn-lg ${
                                  isModified ? "btn-success" : "btn-secondary"
                                } btn-block`}
                                disabled={!isModified}
                              >
                                Aktualisieren
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={onPublish}
                                className={`btn btn-lg ${
                                  !doc._id || doc.draft === false
                                    ? "btn-secondary"
                                    : "btn-success"
                                } btn-block`}
                                disabled={!doc._id || doc.draft === false}
                              >
                                Veröffentlichen
                              </button>
                            ))}
                          {(!doc._id || !!doc.draft) && (
                            <button
                              type="button"
                              onClick={onSaveDraft}
                              className={`btn btn-lg ${
                                isModified ? "btn-primary" : "btn-secondary"
                              } btn-block`}
                              disabled={!isModified}
                            >
                              Speichern
                            </button>
                          )}
                          <button
                            onClick={(e) => setDoc({})}
                            className="btn btn-lg btn-outline-danger btn-block"
                          >
                            Abbrechen
                          </button>
                          <button
                            className="btn btn-lg btn-danger btn-block mt-5"
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch({
                                type: "TOGGLE_TOAST",
                                data: {
                                  open: true,
                                  type: `success`,
                                  message: `Artikel löschen?`,
                                  actions: [
                                    {
                                      callback: (e) => onPostDelete(doc),
                                      title: "Ok",
                                    },
                                  ],
                                },
                              });
                            }}
                          >
                            Artikel löschen
                          </button>
                        </div>
                        <div className="col-6">
                          <button
                            onClick={handlePreview}
                            className={`btn btn-lg btn-block ${
                              isModified || doc._id
                                ? "btn-info"
                                : "btn-secondary disabled"
                            }`}
                          >
                            Preview
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      {doc._attachments && (
                        <img
                          className="img-fluid"
                          src={`data:image/png;base64,${doc._attachments.image.data}`}
                        />
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </WithLoading>
  );
};
export default withAuth(Blog);
