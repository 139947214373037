import React, { useEffect, useState, useContext } from "react";
import "./App.css";
import BlogAuthor from "./BlogAuthor.jsx";
import { AppContext } from "./AppProvider.jsx";
import AuthService from "./AuthService.jsx";
import withAuth from "./withAuth";
import WithLoading from "./WithLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import PreviewPage from "./PreviewPage.jsx";
import { post } from "wreck";

const Auth = AuthService.getInstance();
const Blog = function (props) {
  const { state, dispatch } = useContext(AppContext);
  const [doc, setDoc] = useState({}); // '' is the initial state value
  const [loading, setloading] = useState(true);
  const [preview, setPreview] = useState(false);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    if (state.authors.length === 0) {
      Auth.fetch(`/blog/authors`).then((data) => {
        dispatch({
          type: "FETCH_AUTHORS",
          data: data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          ),
        });
        // dispatch({
        //   type: 'TOGGLE_TOAST',
        //   data: { open: true, type: `success`, message: `Articles fetched` },
        // })
        setloading(false);
      });
    } else {
      setloading(false);
    }
  }, []);

  useEffect(() => {
    setIsModified(false);
  }, [doc._id, doc._rev]);

  const handleImageChange = (e) => {
    convertAndSetImage(e.target.files[0]);
  };
  const onPostEdit = (newDoc) => {
    setDoc(newDoc);
  };
  const convertAndSetImage = (data) => {
    data.error
      ? console.error(data.message)
      : data.arrayBuffer().then((buffer) => {
          const bufferFile = Buffer.from(buffer).toString("base64");
          setDoc({ ...doc, _attachments: { image: { data: bufferFile } } });
        });
  };

  useEffect(() => {
    if (doc._id) {
      return;
    } else {
      let slug = "";
      doc && doc.name
        ? (slug = doc.name
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, "-")
            .replace(/^-+|-+$/g, ""))
        : (slug = "");

      setDoc({ ...doc, slug: slug });
    }
  }, [doc.name]);

  /*   useEffect(() => {
    if (doc.slug) {
      let newSlug = doc.slug;
      const counterRef = { current: 1 };

      const checkSlug = async () => {
        const response = await Auth.fetch(`/blog?slug=${newSlug}`);
        if (response.length > 0) {
          counterRef.current++;
          newSlug = `${doc.slug}-${counterRef.current}`;
          checkSlug();
        } else {
          setDoc((prevDoc) => ({ ...prevDoc, slug: newSlug }));
        }
      };

      checkSlug();
    }
  }, [doc.slug]); */

  const handlePreview = (event) => {
    event.preventDefault();
    setPreview(!preview);
  };

  const onPostDelete = (post) => {
    Auth.fetch(`/blog/authors/${post._id}`, {
      method: "DELETE",
      body: JSON.stringify({ rev: post._rev }),
    }).then((data) => {
      dispatch({ type: "REMOVE_AUTHOR", data: data });
      dispatch({
        type: "TOGGLE_TOAST",
        data: {
          open: true,
          type: `success`,
          message: `Author ${post._id} deleted!`,
        },
      });
    });
  };

  const onPostSubmit = (event) => {
    event.preventDefault();
    var data = new FormData();
    var imagedata = document.querySelector('input[type="file"]').files[0];
    let { ...postDoc } = doc;
    if (imagedata) {
      data.append("image", imagedata, imagedata.name);
    }
    const [...authors] = state.authors;
    postDoc.type = "author";
    if (doc._id) {
      postDoc._id = doc._id;
      postDoc.slug = doc._id;
    }
    if (doc._rev) {
      postDoc._rev = doc._rev;
    }
    var authorIndex = authors.findIndex((obj) => obj._id === doc._id);
    if (authorIndex !== -1) {
      authors.splice(authorIndex, 1);
    } else {
      postDoc.createdAt = new Date().toISOString();
      postDoc._id = `author:${postDoc.slug}`;
      setDoc(postDoc);
    }
    authors.unshift(postDoc);
    data.append("doc", JSON.stringify(postDoc));

    Auth.fetch(
      `/blog/authors`,
      {
        method: "POST",
        body: data,
      },
      true
    )
      .then((res) => {
        if (res.error) {
          dispatch({
            type: "TOGGLE_TOAST",
            data: {
              open: true,
              type: `danger`,
              message: `Error: ${JSON.stringify(res.message)}`,
            },
          });
        } else {
          dispatch({
            type: "FETCH_AUTHORS",
            data: authors,
          });
          dispatch({
            type: "TOGGLE_TOAST",
            data: {
              open: true,
              type: `success`,
              message: doc._rev ? `Updated` : `Created`,
            },
          });
          setDoc(postDoc);
        }
      })
      .catch((e) => {
        console.debug(e);
        dispatch({
          type: "TOGGLE_TOAST",
          data: {
            open: true,
            type: `danger`,
            message: `Error: ${JSON.stringify(e)}`,
          },
        });
      });
  };

  const onPostPublish = (event) => {
    event.preventDefault();
    let { ...postDoc } = doc;
    postDoc.draft = false;
    postDoc.publishedAt = new Date().toISOString();

    Auth.fetch(
      `/blog/authors/${postDoc._id}`,
      {
        method: "PUT",
        body: JSON.stringify(postDoc),
      },
      true
    )
      .then((res) => {
        if (res.error) {
          dispatch({
            type: "TOGGLE_TOAST",
            data: {
              open: true,
              type: `danger`,
              message: `Error: ${JSON.stringify(res.message)}`,
            },
          });
        } else {
          dispatch({
            type: "UPDATE_AUTHOR",
            data: postDoc,
          });
          dispatch({
            type: "TOGGLE_TOAST",
            data: {
              open: true,
              type: `success`,
              message: `Published`,
            },
          });
          setDoc(postDoc);
          setIsModified(false);

          if (preview) {
            setPreview(false);
          }
        }
      })
      .catch((e) => {
        console.debug(e);
        dispatch({
          type: "TOGGLE_TOAST",
          data: {
            open: true,
            type: `danger`,
            message: `Error: ${JSON.stringify(e)}`,
          },
        });
      });
  };

  const onSaveDraft = (event) => {
    event.preventDefault();
    onPostSubmit(event);
  };

  const onPublish = (event) => {
    event.preventDefault();
    onPostPublish(event);
  };

  const createNewAuthor = () => {
    setDoc({});
    setIsModified(false);
  };

  const posttemplate = (
    <div className="table-responsive vh-100">
      <table
        id="blogposts"
        className="py-5 table table-striped table-sm text-left "
      >
        <thead>
          <tr>
            <th scope="col">Created</th>
            <th scope="col">Name</th>
            <th colSpan="col" scope="col">
              Image
            </th>
          </tr>
        </thead>
        <tbody>
          {state.authors &&
            state.authors.map((doc) => (
              <BlogAuthor
                key={doc._id}
                onPostEdit={onPostEdit}
                onPostDelete={onPostDelete}
                doc={doc}
              />
            ))}
        </tbody>
      </table>
    </div>
  );

  const handleInputChange = (e, field) => {
    setDoc({ ...doc, [field]: e.target.value });
    setIsModified(true);
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            {" "}
            <button
              onClick={createNewAuthor}
              className="btn btn-primary btn-lg my-3"
            >
              Neuer Autor
            </button>
            <WithLoading loading={loading} waitfor={state.authors}>
              {posttemplate}
            </WithLoading>
          </div>
          <div className="col-md-6">
            <form className="form-post mb-5 pt-5">
              <div className="mb-3">
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label htmlFor="name" className="sr-only">
                        Name
                      </label>
                      <input
                        value={doc.name || ""}
                        onChange={(e) => handleInputChange(e, "name")}
                        type="text"
                        id="name"
                        className="form-control"
                        placeholder="Name"
                        autoFocus
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="content" className="sr-only">
                    File
                  </label>
                  <input
                    type="file"
                    name="image"
                    onChange={handleImageChange}
                    className="form-control mb-3"
                    placeholder="Image"
                    required={doc._attachments ? false : true}
                  />
                  <div className="row">
                    <div className="col-6">
                      <button
                        type="button"
                        onClick={onSaveDraft}
                        className={`btn btn-lg ${
                          isModified ? "btn-primary" : "btn-secondary"
                        } btn-block`}
                        disabled={!isModified}
                      >
                        Speichern
                      </button>
                      <button
                        onClick={(e) => setDoc({})}
                        className="btn btn-lg btn-outline-danger btn-block"
                      >
                        Abbrechen
                      </button>
                      <button
                        className="btn btn-lg btn-danger btn-block"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch({
                            type: "TOGGLE_TOAST",
                            data: {
                              open: true,
                              type: `success`,
                              message: `Artikel löschen?`,
                              actions: [
                                {
                                  callback: (e) => onPostDelete(doc),
                                  title: "Ok",
                                },
                              ],
                            },
                          });
                        }}
                      >
                        Autor löschen
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  {doc._attachments && (
                    <img
                      className="img-fluid"
                      src={`data:image/png;base64,${doc._attachments.image.data}`}
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withAuth(Blog);
