import React from "react";
import "./App.css";
import ReactMarkdown from "react-markdown";
import AuthService from "./AuthService.jsx";
import withAuth from "./withAuth.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faUpload } from "@fortawesome/free-solid-svg-icons";

const Auth = AuthService.getInstance();
const PreviewPage = function ({
  article,
  onPublish,
  handlePreview,
  isModified,
}) {
  const dateOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  return (
    <div id="preview-page" className="position-relative">
      <div className="bg-secondary">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-6 offset-md-3 py-3 py-md-5">
              <h2>{article.title}</h2>
              <h3>{article.subtitle}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="px-md-5 pb-3 pb-md-5 position-relative">
        <div className="bg-secondary top h-50 left position-absolute w-100"></div>
        <div className="px-xl-5 col-md-6 offset-md-3">
          <div className="px-xl-5 text-center">
            {article._attachments && (
              <img
                className="preview-image"
                src={`data:image/png;base64,${article._attachments.image.data}`}
                alt={article._id}
              />
            )}
          </div>
        </div>
      </div>
      <div className="bg-white pb-5">
        <div className="container">
          <div className="row mb-3">
            <div className="col-lg-6">
              <small className="text-muted">
                {article.createdAt &&
                  new Intl.DateTimeFormat("de-DE", dateOptions).format(
                    Date.parse(article.createdAt)
                  )}
              </small>
              <h2>{article.title}</h2>
              <h3>{article.subtitle}</h3>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="font-semibold">
                <ReactMarkdown>{article.teaser}</ReactMarkdown>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ReactMarkdown>{article.content}</ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
      <div id="preview-buttons" className="position-fixed w-100 right-0 mr-5">
        <div className="container d-flex align-items-center justify-content-between">
          <button
            type="button"
            onClick={handlePreview}
            className="btn btn-lg btn-danger"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mr-3" />
            Zurück
          </button>
          <h3 className="text-white px-5">Preview</h3>
          {article.draft === false ? (
            <button
              type="button"
              onClick={onPublish}
              className={`btn btn-lg ${
                isModified ? "btn-success" : "btn-secondary invisible"
              }`}
              disabled={!isModified}
            >
              Aktualisieren
            </button>
          ) : (
            <button
              type="button"
              onClick={onPublish}
              className={`btn btn-lg ${
                !article._id || article.draft === false
                  ? "btn-secondary"
                  : "btn-success"
              }`}
              disabled={!article._id || article.draft === false}
            >
              Veröffentlichen{" "}
              <FontAwesomeIcon icon={faUpload} className="ml-3" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default withAuth(PreviewPage);
