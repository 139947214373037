import React, { useContext } from "react";
import "./App.css";
import moment from "moment";
import { ReactComponent as Fax } from "./logo-spinning.svg";

const BlogPost = (props) => {
  return (
    <tr key={props.doc._id}>
      <th>
        {props.doc.createdAt
          ? moment(props.doc.createdAt).format("DD.MM.YYYY")
          : ` - `}
      </th>
      <td
        className="w-100 td-link"
        data-toggle="tooltip"
        data-placement="top"
        title="Bearbeiten"
      >
        <a role="button" onClick={() => props.onPostEdit(props.doc)}>
          {props.doc.name}
        </a>
      </td>
      <td className={`text-center`}>
        {props.doc._attachments ? (
          <img
            className={`mh-75 text-light`}
            src={`data:image/png;base64,${props.doc._attachments["image"].data}`}
            alt={props.doc._id}
            style={{ maxWidth: "100px" }}
          />
        ) : (
          <Fax className={`text-light`} />
        )}
      </td>
    </tr>
  );
};
export default BlogPost;
