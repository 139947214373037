import React, { useContext } from "react";
import "./App.css";
import moment from "moment";
import AuthService from "./AuthService.jsx";
import { AppContext } from "./AppProvider.jsx";
import { ReactComponent as Fax } from "./logo-spinning.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const Auth = AuthService.getInstance();

const BlogPost = (props) => {
  const { state, dispatch } = useContext(AppContext);
  return (
    <tr
      key={props.doc._id}
      className={`${props.doc.featured ? "table-warning" : " "}`}
    >
      <th>
        {props.doc.createdAt
          ? moment(props.doc.createdAt).format("DD.MM.YYYY")
          : ` - `}
      </th>
      <td
        className="w-100 td-link"
        data-toggle="tooltip"
        data-placement="top"
        title="Bearbeiten"
      >
        <a role="button" onClick={() => props.onPostEdit(props.doc)}>
          {props.doc.title}
        </a>
      </td>
      <td className={`text-center`}>
        {props.doc._attachments ? (
          <img
            className={`mh-75 text-light`}
            src={`data:image/png;base64,${props.doc._attachments["image"].data}`}
            alt={props.doc._id}
            style={{ maxWidth: "100px" }}
          />
        ) : (
          <Fax className={`text-light`} />
        )}
      </td>
      <td className="text-center">
        {props.doc.type === "draft" ? "Entwurf" : "Veröffentlicht"}
      </td>
      <td className="text-center">
        {props.doc.type === "blogpost" && (
          <button
            className={`feature-button btn btn-sm ${
              props.doc.featured
                ? "btn-warning text-white"
                : "btn-outline-warning"
            }`}
            onClick={() => props.toggleFeatured(props.doc)}
          >
            <FontAwesomeIcon icon={faStar} />
          </button>
        )}
      </td>
    </tr>
  );
};
export default BlogPost;
